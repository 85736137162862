<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <SectionAddForm
        v-if="courseData && pageData"
        :course-data="courseData"
        :page-data="pageData"
      />
    </Layout>
  </div>
</template>

<script>
import Vue from 'vue';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header.vue';
import SectionAddForm from '@/components/section/SectionAddForm.vue';
export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    SectionAddForm
  },
  data() {
    return {
      title: 'Section Add',
      items: [
        {
          text: 'Section',
          href: '/'
        },
        {
          text: 'Group Add',
          active: true
        }
      ],
      courseData: null,
      pageData: null
    };
  },
  async created() {
    //코스 정보
    await this.$store
      .dispatch('course/getCourseDetail', {
        id: this.$route.params.courseId
      })
      .then(res => {
        this.courseData = res.list;
      });

    await this.$store
      .dispatch('section/getSectionInfo', {
        id: this.$route.params.sectionId
      })
      .then(res => {
        this.pageData = res.list;
      });
  }
});
</script>
