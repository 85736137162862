<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">섹션 {{ typeform.id !== '' ? '수정' : '등록' }}</h4>
          <p class="card-title-desc">섹션을 {{ typeform.id !== '' ? '수정' : '등록' }}합니다.</p>
          <form action="#" @submit.prevent="typeForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="">그룹명</label>
                  <input type="text" class="form-control" disabled :value="courseData.name" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-4">
                <div class="form-group">
                  <label>섹션명</label>
                  <input
                    v-model="typeform.title"
                    type="text"
                    class="form-control"
                    placeholder="섹션 이름을 입력하세요."
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.title.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.title.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.title.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-4">
                <div class="form-group">
                  <label>섹션소개</label>
                  <input
                    v-model="typeform.introduce"
                    type="text"
                    class="form-control"
                    placeholder="섹션 소개를 입력하세요."
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.introduce.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.introduce.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.introduce.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-4">
                <div class="form-group">
                  <label>공개여부</label>
                  <select v-model="typeform.openYn" class="form-control">
                    <option value="Y" selected>공개</option>
                    <option value="N">비공개</option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.openYn.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.openYn.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-4">
                <div class="form-group">
                  <label>정렬순서</label>
                  <input
                    v-model="typeform.orderNum"
                    type="text"
                    class="form-control"
                    placeholder="그룹 정렬 순서(높을수록 상위노출)"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.orderNum.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.orderNum.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.orderNum.required">값을 입력해주세요.</span>
                    <span v-if="!$v.typeform.orderNum.numeric">숫자만 입력해주세요.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary me-1">
                    {{ typeform.id !== '' ? '수정' : '등록' }}
                  </button>
                  <button
                    type="reset"
                    class="btn btn-secondary m-l-5 ml-1"
                    @click="$router.push('/course')"
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, numeric} from 'vuelidate/lib/validators';
export default {
  components: {},
  mixins: [validationMixin],
  props: {
    pageData: {
      type: Object,
      default() {
        return {};
      }
    },
    courseData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      typeform: {
        id: '',
        title: '', //섹션명
        introduce: '', // 소개
        openYn: 'Y',
        orderNum: '' //섹션정렬
      },
      typesubmit: false
    };
  },
  mounted() {
    console.log(this.courseData);
    console.log(this.pageData);
    if (Object.keys(this.pageData).length > 0) {
      console.log(this.pageData);
      this.typeform.id = this.pageData.idx;
      this.typeform.title = this.pageData.name;
      this.typeform.introduce = this.pageData.introduce;
      this.typeform.openYn = this.pageData.openYn;
      this.typeform.orderNum = this.pageData.orderNum;
    }
  },
  methods: {
    typeForm() {
      this.typesubmit = true;
      this.$v.typeform.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch('section/sectionAdd', {
            id: this.typeform.id,
            courseId: this.courseData.idx,
            title: this.typeform.title,
            introduce: this.typeform.introduce,
            openYn: this.typeform.openYn,
            orderNum: this.typeform.orderNum
          })
          .then(res => {
            if (res.result) {
              alert(res.message);
              this.$router.push('/section/' + this.courseData.idx);
            } else {
              alert('실패하였습니다.');
            }
          });
      }
    }
  },
  validations: {
    typeform: {
      title: {required},
      introduce: {required},
      openYn: {required},
      orderNum: {required, numeric}
    }
  }
};
</script>

<style lang="scss" scoped></style>
